define('modules/forms/dashboardlinks/js/base.js',["app/config","jquery","app/util","templates","app/forms","app/features/modal"], function(config,$,util,templates,forms,modal) {
    return {
        name: 'dashboardlinks',
        selectors: false,
        remoteData: [config.urls.dashboardlinks],
        routes: [{
            route: 'dashboardlinks',
            fn: 'showDashboardLinks',
            silent: true,
            modal: true
        }],

        data: null,

        initialize: function(data) {
            this.data = data;
            $('.dashboardLinks a').css('height',Math.floor(100/data.length)+'%');
        },

        showDashboardLinks: function() {
            modal.refreshContent(templates.dashboardlinks({links: this.data}),true);
            forms.refreshFormHandlers();
            $('.dashboardLinks a').css('height',Math.floor(100/this.data.length)+'%');
        }
    }
});
