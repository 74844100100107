define('modules/sponsorbar/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'sponsors',
        selectors: '.sponsorBar, .sponsorFlyoutLogos, .sponsorLogoGrid',
        remoteData: [config.urls.sponsors],

        initialize: function (data) {

            // Load up the template
            //$('.sponsorBar, .sponsorFlyoutLogos, .sponsorLogoGrid').append(templates.sponsorlogobar({
            $('.sponsorBar, .sponsorFlyoutLogos, .sponsorLogoGrid').prepend(templates.sponsorlogobar({
                sponsors: data
            }));

        }
    }
});
