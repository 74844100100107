define('app/features/video',['jquery','app/util','jquery.livequery'],function($,util){
    return {
        feature: true,

        controlBarTpl:
        '<div class="video-controls">' +
        '<div class="seekBar"> ' +
        '<div class="togglePlay">' +
        '<span class="play-icon"></span> ' +
        '<span class="pause-icon"></span> ' +
        '</div>' +
        '<div class="seekMeter"> ' +
        '<div class="seekProgress"></div> ' +
        '<input type="range" name="videoSeek" min="0" max="100">' +
        '</div> ' +
        '</div> ' +
        '</div>',

        init: function() {
            if(!window.YT || !window.YT.Player) {
                onYouTubeIframeAPIReady = $.proxy(function(){
                    this.initYoutubeEmbeds();
                },this);
            } else {
                this.initYoutubeEmbeds();
            }
            $('body').livequery('.custom-video',$.proxy(function(el){
                this.initCustomPlayer(el);
            },this));
        },

        initYoutubeEmbeds: function() {
            $('body').livequery('*[data-youtube]',function(){
                // Autoplay defaults to true (1) unless specified as data-autoplay
                var iframeEl = $('<iframe id="player_iframe_YT" src="//www.youtube.com/embed/' + $(this).attr('data-youtube') + '?controls=2&modestbranding=0&autoplay='+($(this).attr('data-autoplay') || 1)+'&showinfo=0&rel=0&enablejsapi=1" style="width:100%;height:100%;"></iframe>');
                $(this).append(iframeEl);
                var videoEl = $(this);
                var player_YT = new YT.Player('player_iframe_YT', {
                    events: {
                        'onReady': function(event){
                            $(videoEl).trigger('videoready');
                        },
                        'onStateChange': function(event) {
                            if (event.data == YT.PlayerState.ENDED) {
                                setTimeout(function(){
                                    $(videoEl).trigger('videoended');
                                },50);
                            }
                        }
                    }
                });

                $(videoEl).on('playVideo',function(){
                    player_YT.playVideo();
                });

                if($(this).attr('data-poster')) {
                    $(this).css('position','relative');
                    var posterEl = $('<div></div>');
                    posterEl.css({position: 'absolute',top:'0px',left:'0px',width:'100%',width:'calc(100% + 2px)',height:'100%',cursor:'pointer',background:'url('+$(this).attr('data-poster')+')','background-size':'cover','background-position':'center center'});
                    $(this).append(posterEl);
                    posterEl.on('click',function(e){
                        e.preventDefault();
                        $(this).hide();
                        player_YT.playVideo();
                    });
                    $(videoEl).on('videoended',function(e){
                        $(posterEl).show();
                    });
                }

            });
        },

        initCustomPlayer: function(element) {

            // TODO - Clean up all the commented out old code below

            // Use modernizr class to remove either the fallback or the video
            if($('html').hasClass('video')) {
                $(element).find('.videoFallback').remove();
            } else {
                $(element).find('.videoSection').remove();
            }

            // Apply default controlbar template
            //$(element).append($(this.controlBarTpl));

            //var video = $(element).find('video')[0];
            //var minutes;
            //var seconds;

            //if a video is found, get the duration
            if (video) {

                // Apply default controlbar template
                $(element).append($(this.controlBarTpl));

                var video = $(element).find('video')[0];
                var minutes;
                var seconds;


                // get video duration
                var i = setInterval(function() {
                    if(video.readyState > 0) {
                        duration = video.duration;
                        minutes = parseInt(video.duration / 60, 10);
                        seconds = video.duration % 60;

                        // (Put the minutes and seconds in the display)

                        clearInterval(i);
                    }
                }, 200);

                // Set up video listeners
                //$(element).find('.videoText').hide();

                $(video).on("timeupdate", function() {

                    // Calculate the slider value
                    var value = (100 / this.duration) * this.currentTime;

                    //generic scoping
                    $(this).parent('.custom-video').find('.video-controls .seekProgress').width(value + '%');

                    $(this).parent('.custom-video').find('.seekMeter input').val(value);

                    if(this.currentTime >= 0 && this.currentTime <= 5) {

                    } else if (this.currentTime > 5 && this.currentTime <= 6) {

                    } else if(this.currentTime > 12 && this.currentTime <= 43) {

                    } else if(this.currentTime > 43 && this.currentTime <= 48) {

                    } else if (this.currentTime >= duration) {
                        this.pause();
                        $(this).trigger('ended');

                        //load and show poster at the end of the video
                        /*this.load();
                         $(element).find('.video-controls').fadeOut();
                         $(element).find('.videoText').fadeIn();
                         $(element).find('.replay-overlay').fadeIn();
                         $(element).find('.videoText .vid-replay, .replay-overlay').click($.proxy(function(){
                         //this.load();
                         this.play();
                         return false;
                         },this));*/
                    }


                });

                $(element).find('.seekMeter input').change(function(){
                    video.currentTime = ($(this).val()/100)*video.duration;
                    return false;
                }).click(function(e){
                    e.stopImmediatePropagation();
                });

                // Handles play and pause elements
                var toggleEls = $.grep([video,$(element).find('.togglePlay')[0],$('.play-overlay')[0]],function(a){
                    return a;
                });
                $(toggleEls).click(function(e){
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    if(video.paused == false) {
                        video.pause();
                    } else if(video.currentTime >= 88.3) {
                        //video.load();
                        //video.play();
                    } else {
                        setTimeout(function(){
                            video.play();
                        },150);
                    }
                });

                // Updates the CSS for controls based on play/pause status
                $(element).find('.video-controls .play-icon').css('display', 'inline-block');
                $(element).find('.video-controls .pause-icon').css('display', 'none');
                $(video).on('pause', function() {
                    $(element).find('.video-controls .play-icon').css('display', 'inline-block');
                    $(element).find('.video-controls .pause-icon').css('display', 'none');
                });
                $(video).on('play', function() {
                    $(element).find('.replay-overlay').fadeOut();
                    $(element).find('.play-overlay').fadeOut();
                    $(element).find('.video-controls .pause-icon').css('display', 'inline-block');
                    $(element).find('.video-controls .play-icon').css('display', 'none');
                    $(element).find('.video-controls').show();
                    $(element).find('.videoText').hide();
                });

            }
            else {
                // TODO - Throw a missing video error
            }


        },

        // Pause any videos that are currently playing
        pauseAllVideos: function() {
            // TODO - Add YT support
            $('video').each(function (key, videoEl) {
                $(videoEl).get(0).pause();
            });
        }
    }
});
