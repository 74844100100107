define('app/app',[

    // Compiled as part of the build process
    'templates',
    'components',
    'hooks',

    // Custom App Config
    'app/config',
    'app/util',
    'app/contentloader',
    'app/forms',
    'app/router',
    'app/user',
    'app/socialauth',

    // App Features
    'app/features/modal',
    'app/features/video',
    'app/features/expandergrid',

    // Testing Feature
    'app/testing',

    // Libs referenced in app initialization
    'jquery',

    // Global Libraries (in transition state)
    'bootstrap/transition',
    'bootstrap/carousel',
    'bootstrap/collapse',
    'bootstrap/slider',
    'bootstrap/modal',
    'bootstrap/select',
    'bootstrapValidator',

    'jquery-ui',
    'jquery.mobile.custom', // Touch events only - custom build
    'jquery.kinetic',
    'jquery.ba-throttle-debounce.min',
    'jquery.cookie',
    'jquery.mousewheel.min',
    'jquery.smoothdivscroll-1.3-min',

    'smartresize',
    'waypoints',
    'modernizr',
    'detectizr'

],function(Templates,components,hooks,config,util,contentloader,forms,router,user,socialAuth,modalFeature,videoFeature,expanderGridFeature,testing,$){

    // Return app init methods
    return {

        buildConfig: null, // App build config from manifest

        // Initializes the app itself
        init: function() {

            var thisApp = this;

            (function() {

                this.init = function() {

                    // Sets the build configuration from generated manifest
                    if(components.build) {
                        thisApp.buildConfig = components.build; // TODO - Deprecated in new util methods
                        util.setBuildConfig(components.build);
                    }

                    //>>excludeStart("production",pragmas.production);
                    testing.init();
                    //>>excludeEnd("production");

                    // Load templates against class
                    this.templates = (window.Templates || {});

                    // Run some custom app util methods, usually specific to a build
                    // TODO - Eventually move this into build JS "options" - high priority refactor
                    util.isSafari7();
                    util.bootstrapNumberCarousel();

                    // Initialize the components
                    thisApp.initComponents();

                    // Initialize various classes
                    if(thisApp.buildConfig.activeModules.indexOf('backend') == -1) {
                        contentloader.init(); // Exclude these below classes on backend builds
                        forms.initialize();
                        socialAuth.init();
                    }
                    router.init();
                    user.init();

                    // App Features
                    // TODO - Make these self-initializing, create an underlying "feature" class (similar to module)
                    modalFeature.init();
                    videoFeature.init();
                    expanderGridFeature.init();

                    hooks.onAppInitialized();

                    $(window).hashchange().trigger('resize'); // This finally kicks off the very first route, and loads the homepage if necessary
                };

                this.init();

            }).call({});

        },

        // Initializes all necessary components based on selectors
        initComponents: function() {

            var initComponent = function(component) {
                // Attempt to load data if its selector exists, or if we explicity set no selector
                var selectors = (component.getSelectors ? component.getSelectors() : component.selectors),
                    remoteData = (component.getRemoteData ? component.getRemoteData() : component.remoteData),
                    routes = (component.getRoutes ? component.getRoutes() : component.routes);

                if(selectors === false || $(selectors).length > 0) {
                    // If data source has been set
                    if(remoteData && remoteData.length > 0) {
                        // TODO -- Wrap the following in a loop in order to load from multiple sources
                        $.getJSON(
                            remoteData[0], // Path
                            $.proxy(component.initialize,component) // Callback
                        ).fail(function(xhr,textErr,err){
                            console.debug('Failed to load module data, module did not initialize.',err,component);
                            // TODO - We need failure handling for UX
                        });
                    } else {
                        component.initialize();
                    }
                    // If a router method has been set
                    if(routes && routes.length > 0) {
                        router.addRoutes(routes,component);
                    }
                }

            };

            components.load(initComponent);
        }
    }
});
