define('modules/forms/message/js/base.js',["app/config","jquery","app/util","templates","app/forms","app/router","app/features/modal"], function(config,$,util,templates,forms,router,modal) {
    return {
        name: 'formmessage',
        selectors: false,
        remoteData: [],

        initialize: function () {},

        showMessage: function() { // Not currently used directly, instead we call the same below logic from forms.js
            modal.refreshContent(templates.formmessage(),true);
            forms.refreshFormHandlers();
        }
    }
});
