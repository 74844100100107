define('modules/forms/resetpassword/js/base.js',["app/config","jquery","app/util","templates","app/forms","app/features/modal"], function(config,$,util,templates,forms,modal) {
    return {
        name: 'resetpassword',
        selectors: false,
        remoteData: [],
        routes: [{
            route: 'reset',
            fn: 'showReset',
            silent: true,
            modal: true,
            priority: 5
        }],

        initialize: function () { },

        showReset: function() {
            modal.refreshContent(templates.resetpassword(),true);
            forms.refreshFormHandlers();
        }
    }
});
