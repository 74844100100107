define('modules/forms/register/js/base.js',["app/config","jquery","app/util","templates","app/forms","app/features/modal"], function(config,$,util,templates,forms,modal) {
    return {
        name: 'register',
        selectors: false,
        remoteData: [],
        routes: [{
            route: 'register',
            fn: 'showRegister',
            silent: true,
            modal: true,
            priority: 5
        }],

        initialize: function () { },

        showRegister: function() {
            if (config.rampitRegTimeLeft < 1) {
                modal.refreshContent(templates.validationmessage({
                    message: (config.regConfig ? (config.regConfig.closeMessage || '') : ''),
                    messageTitle: (config.regConfig ? (config.regConfig.closeTitle || '') : '')
                }),true);
            } else {
                modal.refreshContent(templates.register(),true);
            }
            forms.refreshFormHandlers(); 
        }
    }
});
