define('hooks',['jquery', 'app/util'], function ($, util) {
    var methods = {
        onDocumentReady: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - document ready.')
            //>>excludeEnd("production");
        },
        onAppInitialized: function () {
            //>>excludeStart("production",pragmas.production);
            console.debug('Project hook - app initialized.')
            //>>excludeEnd("production");

            //code for adding hidden class on navbar on judges details open
            $('.judgesPageContainer').on('click', '.judgeBox', function () {

                $('.navbarWrap').addClass('hidden');
            });

            //code for removing the hidden class on navbar on judges details closing
            $('.judgesPageContainer').on('click', '.judgeDetailClose', function () {

                $('.navbarWrap').removeClass('hidden');
            });

            $('.navbar-share-toggle').on('mouseenter', function() {
                var self = $(this);

                self.siblings('.social-links-inner').addClass('expanded');
            });

            $(".social-links-wrap").on('mouseleave', function() {
                var self = $(this);

                self.find('.social-links-inner').removeClass('expanded');
            });

            $('.navbar-share-toggle').on('click', function () {
                var self = $(this);

                self.siblings('.social-links-inner').toggleClass('expanded');
            });

            // array.find ie support
            if (typeof Array.prototype.find === 'undefined') {

                Array.prototype.find = function (callback, thisArg) {
                    for (var i = 0; i < this.length; i++) {
                        if (callback.call(thisArg || window, this[i], i, this))
                            return this[i];
                    }
                    return undefined;
                };
            }
        }
    };
    $(document).ready(methods.onDocumentReady);

    return methods;
});

