define('modules/process/horizontal/js/base.js',["app/module","app/config", "jquery", "app/util", "templates", "moment"], function (module, config, $, util, templates, moment) {
    return new module({
        name: 'process.horizontal',
        selectors: '.processHorizontalStepWrap',
        remoteData: [(config.urls.process)],
        
        config: {
            cycleProcess: true,
            flipAnimation: true,
            processCarousel: false,
            dateBased: true
        },

        initialize: function (data) {
            this.data = (data || this.data);
            this.renderTemplates(this.data);
            this.addClickHandlers(data);
            if(this.getConfig().dateBased){
            	this.setInitialState();
            }
            else {
                $(this.getSelectors()).find('.process-card[data-processid="1"]').addClass('current-active-card');
			}

            if(this.getConfig().processCarousel){
                // init carousel
                $(this.getSelectors()).find('.carousel.slide').carousel({
                    cycle: false,
                    interval: false
				})
				.swiperight($.proxy(function(){
					this.onPrevCard(this.data);
				},this))
				.swipeleft($.proxy(function(){
					this.onNextCard(this.data);
				},this))
            }
        },

		methods: {
			renderTemplates: function (data) {
				$.each(data, function (i, r) {
					data[i].total = data.length;
				});
				$(this.getSelectors()).append(templates.processsteps({
					steps: data
				}));
			},

			setInitialState: function() {
				var currentDate = new moment(); // Current date, feed moment a date string for testing purposes
				var pastPoints = $.grep(this.data,function(a){
					return (currentDate.diff(new Date(a.startDate)) > 0);
				});
				var processId = (pastPoints.length > 0 ? pastPoints[pastPoints.length-1].id : 1);
				this.showStepContent(processId);
				setTimeout($.proxy(function(){
					this.setCardPosition(processId);
					$(this.getSelectors()).find('.process-card[data-processid="1"]').addClass('current-active-card');
				},this),500);
			},

			addClickHandlers: function (data) {
				var module = this;
				$(this.getSelectors()).find('.process-step-next-wrap').find('i.next-card').click(function (e) {
					e.preventDefault();
					module.onNextCard(data);
				});
				$(this.getSelectors()).find('.process-step-next-wrap').find('i.prev-card').click(function (e) {
					e.preventDefault();
					module.onPrevCard(data);
				});
				
				
				/*$(this.getSelectors()).find('.process-card').on('click', $.proxy(function (e) {
                    
					e.preventDefault();
					$(this).siblings('.current-active-card').removeClass('current-active-card');
					$(this).addClass('current-active-card');
					
					if(module.getConfig().processCarousel){
						$(this.getSelectors()).find('.carousel.slide').carousel($(this).attr('data-processid'));
					}
					module.showStepContent($(this).attr('data-processid'));
				
                }, this));*/
                
                
                $(this.getSelectors()).find('.process-card').on('click', function (e) {
                    
					e.preventDefault();
					$(this).siblings('.current-active-card').removeClass('current-active-card');
					$(this).addClass('current-active-card');

					module.showStepContent($(this).attr('data-processid'));
					
					if(module.getConfig().processCarousel){
						//$(this.getSelectors()).find('.carousel.slide').carousel($(this).attr('data-processid'));
						$('#processCaro.carousel.slide').carousel(parseInt($(this).attr('data-processid'))-1);
					}
				
                });
                
				
			},

			onNextCard: function(data) {
				if(this.getConfig().processCarousel){
					var currentCardId = $(this.getSelectors()).find('.item.active .step-card-content').attr('data-processid'),
						nextCardEl = $(this.getSelectors()).find('.item .step-card-content[data-processid="'+(parseInt(currentCardId)+1)+'"]');
					
					if($(nextCardEl).length > 0) {
						$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
						$(this.getSelectors()).find('.process-card[data-processid="'+(parseInt(currentCardId)+1)+'"]').trigger('click').addClass('current-active-card');
					}
					else {
						if(this.getConfig().cycleProcess) {
							$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
							$(this.getSelectors()).find('.process-card[data-processid="'+(parseInt(1))+'"]').trigger('click').addClass('current-active-card');
						}
					}
						
				}
				else {
					var currentCardId = $(this.getSelectors()).find('.step-card-content').attr('data-processid'),
						nextCardEl = $(this.getSelectors()).find('.process-card[data-processid="'+(parseInt(currentCardId)+1)+'"]');
					
					if($(nextCardEl).length > 0) {	
						$(nextCardEl).trigger('click');
						$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
						nextCardEl.addClass('current-active-card');
				
					}
					else {
						if(this.getConfig().cycleProcess) {
							$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
							$(this.getSelectors()).find('.process-card[data-processid="1"]').trigger('click').addClass('current-active-card');
						}
					}
				}
				
			},

			// possibly write a config for this, but currently no prev button
			onPrevCard: function(data) {
				if(this.getConfig().processCarousel){
					var currentCardId = $(this.getSelectors()).find('.item.active .step-card-content').attr('data-processid'),
					prevCardEl = $(this.getSelectors()).find('.item .step-card-content[data-processid="'+(parseInt(currentCardId)-1)+'"]');
					
					if($(prevCardEl).length > 0) {
						$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
						$(this.getSelectors()).find('.process-card[data-processid="'+(parseInt(currentCardId)-1)+'"]').trigger('click').addClass('current-active-card');
					}
					else {
						if(this.getConfig().cycleProcess) {
							var lastProcessCardID = data[data.length-1].id;
							$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
							$(this.getSelectors()).find('.process-card[data-processid="' + lastProcessCardID + '"]').trigger('click').addClass('current-active-card');
						}
					}
						
				}
				else {
					var currentCardId = $(this.getSelectors()).find('.step-card-content').attr('data-processid'),
						prevCardEl = $(this.getSelectors()).find('.process-card[data-processid="'+(parseInt(currentCardId)-1)+'"]');
					if($(prevCardEl).length > 0) {
						$(prevCardEl).trigger('click');
						$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
						prevCardEl.addClass('current-active-card');
					}
					else {
						if(this.getConfig().cycleProcess) {
							$(this.getSelectors()).find('.current-active-card').removeClass('current-active-card');
							$(this.getSelectors()).find('.process-card[data-processid="'+(parseInt(data.length))+'"]').trigger('click').addClass('current-active-card');;
						}
					}
				}
			},

			setCardPosition: function(processId) {
				if(processId) {
					var initialOffset = $(this.getSelectors()).find('.process-content-container').offset().left - $(this.getSelectors()).find('.process-container').offset().left,
						cardWidth = $(this.getSelectors()).find('.cards-container .process-card').outerWidth(true),
						cardIndex = $(this.getSelectors()).find('.process-card[data-processid="' + processId + '"]').index();
                    var leftOffset = initialOffset - (cardWidth * cardIndex) - (4*(1+cardIndex)); // TODO - This might ned refinement for the margin styles
				} else {
					var leftOffset = 150;
				}
				$(this.getSelectors()).find('.cards-container').css('transform','translateX('+leftOffset+'px)');
			},

			showStepContent: function(processId) {
				var records = $.grep(this.data,function(r){
					return r.id == processId;
				});
				if(records.length > 0) {
				    if(this.getConfig().flipAnimation) {
				        if($(this.getSelectors).find('.process-content-container .flipper').hasClass('flipping')) {
				            return;
                        }
				        var origEl = $(this.getSelectors()).find('.process-content-container .process-content-inner');
                        var clonedEl = $(this.getSelectors()).find('.process-content-container .process-content-inner').clone();
                        $(clonedEl).insertAfter($(origEl));
                        if($(this.getSelectors()).find('.process-content-container .flipper').length == 0) {
                            $(this.getSelectors()).find('.process-content-container .process-content-inner').wrapAll("<div class='flipper' />");
                        }
                        $(clonedEl).addClass('back').html(templates.processstepdetail(records[0]));
                        $(this.getSelectors()).find('.process-content-container .flipper').addClass('flipping');
                        setTimeout($.proxy(function(){
                            $(origEl).remove();
                            $(clonedEl).removeClass('back').addClass('front');
                            $(this.getSelectors()).find('.process-content-container .flipper').removeClass('flipping');
                        },this),750); // This timeout has to be greater than transition/animation speed
                    } else {
                        $(this.getSelectors()).find('.process-content-container .process-content-inner').html(templates.processstepdetail(records[0]));
                    }
                    this.setCardPosition(processId);
                    $(this.getSelectors()).find('.process-step-next-wrap .current').text(processId);
				}
			}
        }

    });
});

