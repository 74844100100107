define('app/socialauth',['app/util','jquery','app/config','app/forms'],function(util,$,config,forms){
    return {
        init: function() {

            var fb_app = window.fb_app || '122254468152080';
            var fb_uid = window.fb_uid || '';
            var fb_accessToken = window.fb_accessToken || '';

            var serialize = function(obj){
                var str=[];
                for(var p in obj) {
                    if(obj.hasOwnProperty(p)){
                        str.push(encodeURIComponent(p)+"="+ encodeURIComponent(obj[p]));
                    }
                }
                return str.join("&");
            };

            var authSocial=[];

            // Facebook
            $(document).ready(function(){
                $(document).on("click",'#facebookButton',function(event){
                    event.preventDefault();
                    if(fb_accessToken.length==0){
                        FB.login(function(response){
                            if(response.authResponse){
                                fb_accessToken=response.authResponse.accessToken;
                                forms.methods.doLogin.call(forms,'pages/auth.aspx?method=fb&'+ serialize({fb_accessToken:fb_accessToken}),"#login");
                            }
                        },{
                            scope:'email'
                        });
                    } else{
                        forms.methods.doLogin.call(forms,'pages/auth.aspx?method=fb&'+ serialize({fb_accessToken:fb_accessToken}),"#login");
                    }
                });
            });
            // Load the SDK Asynchronously
            window.fbAsyncInit = function () {
                FB.init({
                    appId: fb_app, // App ID
                    status: true, // check login status
                    cookie: true, // enable cookies to allow the server to access the session
                    xfbml: true  // parse XFBML
                });

                // Additional initialization code here
                FB.Event.subscribe('auth.authResponseChange', function (response) {
                    if (response.status === 'connected') {
                        fb_uid = response.authResponse.userID;
                        fb_accessToken = response.authResponse.accessToken;
                    } else if (response.status === 'not_authorized') {
                        // the user is logged in to Facebook,
                        // but has not authenticated your app
                    } else {
                        // the user isn't logged in to Facebook.
                    }
                });
            };
            $('body').append('<div id="fb-root"></div>');
            (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id; async = true;
                js.src = "//connect.facebook.net/en_US/all.js#xfbml=1&version=v2.8";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));

            // Google
            gPlusRender = function(){
                $(document).on("click",'#googleButton',function(event){
                    event.preventDefault();
                    gapi.auth.signIn({
                        callback: function(authResult) {
                            if(authResult['status']['signed_in']){
                                if(authResult['status']['method']=='PROMPT'){
                                    authSocial.access_token=authResult['access_token'];
                                    gapi.client.load('plus','v1',function(){
                                        var request=gapi.client.plus.people.get({'userId':'me'});
                                        request.execute(function(resp){
                                            authSocial.email=resp.emails[0].value;
                                            var urlGoogle = 'pages/auth.aspx?method=google&'+ serialize({
                                                email: authSocial.email,
                                                access_token: authSocial.access_token
                                            });
                                            forms.methods.doLogin.call(forms,urlGoogle,"#login");
                                        });
                                    });
                                }
                                if(authResult['status']['method']=='AUTO'){
                                    // TODO - Why is this necessary?
                                }
                            }
                        }
                    });
                });
                $('iframe[name*="oauth"]').prependTo('#form1'); // TODO - Is this still required?
            };
            (function(){
                var po=document.createElement('script');
                po.type='text/javascript';
                po.async=true;
                po.src='//apis.google.com/js/client:plusone.js?onload=gPlusRender';
                var s=document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(po,s);
            })();
        }
    }
});



