define('modules/finalists/gridoverlay/js/base.js',[
    'app/config', 'jquery', 'templates', 'skrollr', 'waypoints', 'app/features/video'
], function (config, $, templates, skrollr) {
    return {
        name: 'finalists',
        selectors: '.finalistContainer, .granteeContainer',
        remoteData: [config.urls.finalists],

        routes: [
            {
                route: 'finalists',
                fn: function (hash) {
                    this.closeAllOverlays(hash);
                },
                extra: true,
                delay: 500
            }, {
                route: 'finalists/(.*)',
                fn: function (hash) {
                    this.goToHash2(hash);
                },
                extra: true,
                delay: 2000
            },
            {
                route: '.*',
                fn: function (hash) {
                    if (hash == 'results') {
                        $('body').addClass('results-page');
                    }
                    else {
                        $('body').removeClass('results-page');
                    }
                },
                extra: true,
                delay: 100
            },
            {
                route: '.*',
                fn: function () {
                    this.resetAwardsVideo();
                },
                extra: true,
                delay: 250
            }
        ],
        initialize: function (data) {
            var initHtml;

            this.data = (data || this.data);
            this.renderTemplates(this.data);
            //this.finalistOrginalData(this.data);
            //this.finalistTabHandler(this.data);
            //this.refreshFinalistSlider(data);
            this.finalistOverlays(data);

            $('.finalist-wrapper').find('.carousel.slide')
                .carousel({
                    interval: 7500,
                    pause: null
                }).swipeleft(function () {
                $(this).carousel('next');
            }).swiperight(function () {
                $(this).carousel('prev')
            });

        },

        closeAllOverlays: function (hash) {

            if ($('.finalist-overlay-grid').hasClass('open')) {
                $('.finalistContainer .close-overlay')[0].click();
                $('.granteeContainer .close-overlay')[0].click();
            }

        },

        goToHash2: function (hash) {
            var scrollToTeam = hash.split('/')[1];

            $(".intro-section-btn[data-scrollto='.section.finalists']").trigger('click');

            var finalistItem = $('.finalist-post[data-teamid="' + scrollToTeam + '"]');
            if (finalistItem) {

                var innerCol = finalistItem.find('.inner-col');
                $(innerCol).trigger('tap');

            }
        },

        goToHash: function (hash) {
            var scrollToTeam = hash.split('/')[1];

            if (scrollToTeam <= 5) {

                $('.finalist-page .intro-section.main').hide();
                $('.finalist-overview').hide();
                $('.participant-overlay-grid').hide();

                $('html,body').scrollTop(0);

                $('.finalist-overlay-grid').fadeIn(500).addClass('open');
                $('.intro-section-container').fadeIn(500);
                $('.intro-section-container .finalist').fadeIn(500);

                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: $('.finalist-container[data-finalist="' + scrollToTeam + '"]').offset().top - 50
                    }, 1000);
                    $('body').trigger('pagenavupdate');
                }, 2000);

                $('body').trigger('pagenavupdate');
                $('.finalist-page .finalistSideNavWrap').fadeIn();
                $('.finalist-page .closeNav').fadeIn();

            } else {

                $('.finalist-page .intro-section.main').hide();
                $('.finalist-overview').hide();
                $('.finalist-overlay-grid').hide();

                $('html,body').scrollTop(0);

                $('.participant-overlay-grid').fadeIn(500).addClass('open');
                $('.intro-section-container').fadeIn(500);
                $('.intro-section-container .participant').fadeIn(500);

                setTimeout(function () {
                    $('body').trigger('pagenavupdate');
                    $('html, body').animate({
                        scrollTop: $('.participant-container[data-id="' + scrollToTeam + '"]').offset().top - 50
                    }, 1000);
                }, 2000);

                $('body').trigger('pagenavupdate');
                $('.finalist-page .finalistSideNavWrap').fadeIn();
                $('.finalist-page .closeNav').fadeIn();

            }
        },

        resetAwardsVideo: function () {
            $('.videoSection-container').empty();
            $('.videoSection-container').html('<div class="videoSection-video" data-youtube="iANvmB_ozlM" data-autoplay="0"></div>');
        },

        renderTemplates: function (data) {

            data.sort(function (a, b) {
                return a.id - b.id;
            });

            $.each(data, function (i, r) {
                data[i].total = data.length;
            });

            $('.finalistContainer').append(templates.finalists({steps: data}));
            var finalistGroupOne = $('.finalistContainer .finalist-post').length;
            $('.finalistContainer .finalist-overlay-grid .countTotal').html(finalistGroupOne);

            $('.honorableMentionContainer').append(templates.honorablementions({steps: data}));
            var finalistGroupTwo = $('.honorableMentionContainer .finalist-post').length;
            $('.honorableMentionContainer .finalist-overlay-grid .countTotal').html(finalistGroupTwo);

            var divs;
            var carouselInner = $('.honorableMentionSliderCarousel .carousel-inner').html();

            $(window).smartresize($.proxy(function () {

                $('.honorableMentionSliderCarousel .carousel-inner').html(carouselInner);
                divs = $('.honorableMentionContainer .finalist-post');

                if ($(window).width() > 768) {

                    for (var i = 0; i < divs.length; i += 3) {
                        divs.slice(i, i + 3).wrapAll('<div class="item honorableMentionCarouselItem"><div class="container"><div class="row"></div></div></div>');
                    }

                }
                else {
                    divs.wrapAll('<div class="item honorableMentionCarouselItem"><div class="container"><div class="row"></div></div></div>');
                }
                $('.honorableMentionCarouselItem:first').addClass('active');
            }, this));

            $(window).trigger('resize');
        },

        finalistOverlays: function (data) {

            var module = this;


            $('body').trigger('pagenavupdate');

            $(document).on('click', '.close-overlay', function () {

                $('.finalistSideNavbarWrap').removeClass('hidden').trigger('pagenavupdate');

                //stop any videos
                $('.finalist-container .finalist-video .video-iframe-container').each(function () {
                    $(this).html('');
                });

                if ($('.finalist-overlay-grid').hasClass('open')) {

                    $('.finalist-overlay-grid').fadeOut().removeClass('open');
                    $('.finalist-container').removeClass('active');

                    $('body').trigger('pagenavupdate').removeClass('locked finalistsDetailOpen');
                }

                $('.navbarWrap').fadeIn();

            });

            $(document).on('click', '.go-to-finalists', function (e) {
                e.preventDefault();

                module.resetAwardsVideo();

                $('.finalistSideNavbarWrap').addClass('hidden').trigger('pagenavupdate');
                $('body').addClass('locked finalistsDetailOpen');
                var parId = $(this).parents('.finalist-col').attr('data-id');
                var groupId = $(this).attr('data-group');
                $('.pageContainer.active .finalist-container[data-finalist="' + parId + '"]').addClass('active');

                var activeIndex = $('.pageContainer.active .finalist-overlay-grid[data-group="' + groupId + '"] .finalist-container.active').index();

                $('.pageContainer.active .finalist-overlay-grid[data-group="' + groupId + '"] .finalistSideNav .currentCount').html(activeIndex + 1);
                $('.pageContainer.active .finalist-overlay-grid[data-group="' + groupId + '"]').fadeIn(500).addClass('open');

                $('.finalist-page .finalistSideNavWrap').fadeIn();
                $('.finalist-page .closeNav').fadeIn();

                $('.navbarWrap').hide();

                module.finalistGroupNav();
                module.updateVideo();
            });

        },

        updateVideo: function () {
            $('.finalist-container .finalist-video .video-iframe-container').each(function () {
                $(this).html('');
            });

            var activeFinalist = $('.finalist-container.active').attr('data-finalist');
            var videoUrl = $('.pageContainer.active .finalist-container[data-finalist="' + activeFinalist + '"] .finalist-video').attr('video-url');
            $('.pageContainer.active .finalist-container[data-finalist="' + activeFinalist + '"]').find('.video-iframe-container').html('<div class="custom-video" data-youtube="' + videoUrl + '" data-autoplay="0"></div>');
        },
        finalistGroupNav: function () {
            var moduleGetSelectors = $('.finalistContainer, .honorableMentionContainer').find('.finalist-overlay-grid.open'),
                activeIndex,
                lastIndex,
                nextIndex,
                prevIndex,
                module = this;

            // handle jude nav in it's own module
            $('.finalistContainer, .honorableMentionContainer').find('.finalist-overlay-grid.open .finalistSideNav .prev, .finalist-overlay-grid.open .finalistSideNav .next').unbind('click').on('click', $.proxy(function (e) {
                activeIndex = +(moduleGetSelectors.find('.finalistSideNav .currentCount').html());
                nextIndex = activeIndex + 1;
                prevIndex = activeIndex - 1;
                lastIndex = +(moduleGetSelectors.find('.finalistSideNav .countTotal').html());


                //next at end
                if ($(e.currentTarget).hasClass('next') && (activeIndex == lastIndex)) {
                    moduleGetSelectors.find('.finalist-container.active').removeClass('active');
                    moduleGetSelectors.find('.finalist-container').eq(0).addClass('active');
                    moduleGetSelectors.find('.finalistSideNav .currentCount').html('1');
                }
                // next
                else if ($(e.currentTarget).hasClass('next')) {
                    moduleGetSelectors.find('.finalist-container.active').removeClass('active').next('.finalist-container').addClass('active');
                    moduleGetSelectors.find('.finalistSideNav .currentCount').html(nextIndex);
                }
                // prev at end
                else if ($(e.currentTarget).hasClass('prev') && activeIndex == 1) {
                    moduleGetSelectors.find('.finalist-container.active').removeClass('active');
                    moduleGetSelectors.find('.finalist-container').eq(lastIndex - 1).addClass('active');
                    moduleGetSelectors.find('.finalistSideNav .currentCount').html(lastIndex);
                }
                else if ($(e.currentTarget).hasClass('prev')) {
                    moduleGetSelectors.find('.finalist-container.active').removeClass('active').prev('.finalist-container').addClass('active');
                    moduleGetSelectors.find('.finalistSideNav .currentCount').html(prevIndex);
                }

                module.updateVideo();

                $('.finalist-overlay-grid.open').scrollTop(0);
            }));


        }
    }
});

